import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from "../DataStorage";
import { gsap, Power1, Power3, Back } from "gsap";

export class HistoryTutorialView
{
	private element: HTMLElement;

	private hand: HTMLElement;
	private hand_1: HTMLElement;
	private hand_2: HTMLElement;

	private isActiv: boolean;
	private point_x: number[] = [];

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#historyTutorialModal");

		this.hand = this.element.querySelector("._hand");
		this.hand_1 = this.element.querySelector("._hand_1");
		this.hand_2 = this.element.querySelector("._hand_2");

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_HISTORY_TUTORIAL, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_HISTORY_TUTORIAL, this.removeBindThis);

	}
	public add(): void
	{
		if(this.isActiv) return;

		this.point_x = [];
		for (let i = 0; i < DataStorage.historyPointX.length; i++)
		{
			this.point_x[i] = (DataStorage.historyPointX[i]+DataStorage.historyPointX[i+1])/2/1500*DataStorage.window_w-DataStorage.window_w/2;
		}

		this.isActiv = true;

		gsap.set(this.element, {css:{opacity:1, display:"block"}});

		gsap.killTweensOf(this.hand);
		gsap.set(this.hand, {css:{opacity:0, display:"none", x:-DataStorage.window_w/750*220, y:DataStorage.window_w/750*200}});
		gsap.to(this.hand, 0.6, {css:{opacity:1, display:"block", y:0}, ease:Power1.easeOut, delay:0.6});

		gsap.to(this.hand, 1.2, {css:{x:DataStorage.window_w/750*150}, ease:Power3.easeInOut, delay:1.5, onUpdate:this.ease.bind(this)});
		gsap.to(this.hand, 0.6, {css:{y:-DataStorage.window_w/750*30}, ease:Power1.easeIn, delay:1.5});
		gsap.to(this.hand, 0.6, {css:{y:-DataStorage.window_w/750*10}, ease:Power1.easeOut, delay:1.5+0.6});

		gsap.set(this.hand_1, {css:{display:"none"}, delay:1.5-0.1});
		gsap.set(this.hand_2, {css:{display:"block"}, delay:1.5-0.1});

		let _stop = 0.6;

		gsap.to(this.hand, 1.2, {css:{x:-DataStorage.window_w/750*220}, ease:Power3.easeInOut, delay:2.8+_stop, onUpdate:this.ease.bind(this)});
		gsap.to(this.hand, 0.6, {css:{y:-DataStorage.window_w/750*30}, ease:Power1.easeIn, delay:2.8+_stop});
		gsap.to(this.hand, 0.6, {css:{y:-DataStorage.window_w/750*0}, ease:Power1.easeOut, delay:2.8+0.6+_stop});

		gsap.set(this.hand_1, {css:{display:"block"}, delay:3.0+0.9+_stop});
		gsap.set(this.hand_2, {css:{display:"none"}, delay:3.0+0.9+_stop});

		gsap.to(this.hand, 1.2, {css:{opacity:0, display:"none", y:DataStorage.window_w/750*200}, ease:Power1.easeIn, delay:3.0+1.2+_stop, onComplete:this.remove.bind(this)});

	}
	public ease(): void
	{
		DataStorage.historyNum = 777;

		for (let i = 0; i < this.point_x.length; i++)
		{
			if(Number(gsap.getProperty(this.hand, "x"))<this.point_x[i])
			{
				DataStorage.historyNum = i;
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.CHANGE_HISTORY));
				break;
			}
		}

		if(DataStorage.historyNum==777)
		{
			DataStorage.historyNum = this.point_x.length-1;
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.CHANGE_HISTORY));
		}

	}
	public remove(): void
	{
		gsap.set(this.element, {css:{opacity:0, display:"none"}});

	}
}