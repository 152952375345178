import { Event } from "../events/EventDispatcher";
import { DataStorage } from "../DataStorage";
import { SceneEvent } from "../SceneEvent";
import { HistoryContents } from "./HistoryContents";
import { HistoryPoint } from "./HistoryPoint";
import * as PIXI from 'pixi.js';
import { gsap, Power1 } from "gsap";
import Cookies from 'js-cookie';

export class HistoryView
{
	private element: HTMLElement;

	private app: PIXI.Application;
	private contents_container: PIXI.Container;
	private navigation_container: PIXI.Container;
	private navigation: PIXI.Sprite;
	private img_navigation: PIXI.Texture;

	private point_x: number[] = [];

	private addBindThis: Function;
	private removeBindThis: Function;
	private resizeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#history");

		this.app = new PIXI.Application();
		(async () => {
			await this.app.init({
				backgroundAlpha:0,
			});
			this.element.appendChild(this.app.canvas);

			this.img_navigation = await PIXI.Assets.load('/img/history/navigation.png');
			for (let i = 0; i < DataStorage.historyData.length; i++)
			{
				DataStorage.historyData[i].photo = await PIXI.Assets.load('/img/history/'+DataStorage.historyData[i].photo);
			}

			this.init();
		})();
	}
	public init(): void
	{
		this.contents_container = new PIXI.Container();
		this.app.stage.addChild(this.contents_container);

		this.navigation_container = new PIXI.Container();
		this.app.stage.addChild(this.navigation_container);

		this.navigation = PIXI.Sprite.from(this.img_navigation);
		this.navigation.width = 1500;
		this.navigation.height = 300;
		this.navigation.x = 750;
		this.navigation.anchor.set(0.5, 1.0);
		this.navigation_container.addChild(this.navigation);
		this.navigation.interactive = true;
		this.navigation.on('pointermove', this.onDragMove.bind(this));

		for (let i = 0; i < DataStorage.historyData.length; i++)
		{
			new HistoryContents(this.contents_container, i);
			new HistoryPoint(this.navigation_container, i);
		}

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_HISTORY, this.addBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_OPEN_COMPLETE, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_HISTORY, this.removeBindThis);

		this.resizeBindThis = this.resize.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.WINDOW_RESIZE, this.resizeBindThis);
		this.resize();

		DataStorage.historyPointPosition.x = 126.2;
		DataStorage.historyPointPosition.y = 328.1;
		DataStorage.historyPointPosition.radius = 1214;
		DataStorage.historyPointPosition.red = 1.49;
		DataStorage.historyPointPosition.start = 249.8;
		if(DataStorage.gui)
		{
			DataStorage.gui.add(DataStorage.historyPointPosition, "x", 0, 750, 0.1).name("x").onChange(this.changeParameter.bind(this));
			DataStorage.gui.add(DataStorage.historyPointPosition, "y", 250, 350, 0.1).name("y").onChange(this.changeParameter.bind(this));
			DataStorage.gui.add(DataStorage.historyPointPosition, "radius", 600, 1500, 1).name("radius").onChange(this.changeParameter.bind(this));
			DataStorage.gui.add(DataStorage.historyPointPosition, "red", 0, 3, 0.01).name("red").onChange(this.changeParameter.bind(this));
			DataStorage.gui.add(DataStorage.historyPointPosition, "start", 200, 350, 0.1).name("start").onChange(this.changeParameter.bind(this));
		}
		this.changeParameter();

		for (let i = 0; i < DataStorage.historyPointX.length; i++)
		{
			this.point_x[i] = (DataStorage.historyPointX[i]+DataStorage.historyPointX[i+1])/2;
		}
	}
	public add(): void
	{
		if(DataStorage.state!="history") return;
		
		DataStorage.offMenu = "history";
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.MENU_OFF));

		gsap.killTweensOf(this.element);
		gsap.set(this.element, {css:{opacity:0, display:"none", scale:0.95}});
		gsap.to(this.element, 0.3, {css:{opacity:1, display:"block", scale:1.0}, ease:Power1.easeInOut, onComplete:this.addComplete.bind(this)});

		if(!Cookies.get('historyTutorial'))
		{
			Cookies.set('historyTutorial', 'true', {expires:DataStorage.cookies_expires});
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_HISTORY_TUTORIAL));
		}
	}
	public addComplete(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_COVER));		//クリック許可
		
	}
	public remove(): void
	{
		gsap.killTweensOf(this.element);
		gsap.to(this.element, 0.3, {css:{opacity:0, display:"none", scale:0.95}, ease:Power1.easeInOut});

	}
	public changeParameter(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.SET_HISTORY_POSITION));

	}
	public onDragMove(event): void
	{
		DataStorage.historyNum = 777;

		for (let i = 0; i < this.point_x.length; i++)
		{
			if(event.data.global.x<this.point_x[i])
			{
				DataStorage.historyNum = i;
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.CHANGE_HISTORY));
				break;
			}
		}

		if(DataStorage.historyNum==777)
		{
			DataStorage.historyNum = this.point_x.length-1;
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.CHANGE_HISTORY));
		}
	}
	public resize(): void
	{
		const _height =  Math.max(1500/DataStorage.window_w*DataStorage.window_h, 2540);
		this.app.renderer.resize(1500, _height);

		this.contents_container.y = (_height-2540)/2;
		this.navigation_container.y = _height;

	}
}