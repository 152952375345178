import { Event } from "../events/EventDispatcher";
import { DataStorage } from "../DataStorage";
import { gsap, Power1 } from "gsap";

export class InsideCoverView
{
	private element: HTMLElement;
	private state: String;			//main, sub
	private num: number;

	private text: object[] = [
		["RELATIONSHIP", "絆"],
		["INHERITANCE", "継承"],
		["ASPIRATION", "憧れ"],
		["BRIDAL", "結婚"],
		["ENCOURAGEMENT", "自信"],
	];

	private div: HTMLElement;

	constructor(element:HTMLElement, state:String, num:number)
	{
		this.element = element;
		this.state = state;
		this.num = num;

		this.div = document.createElement('div');
		this.element.appendChild(this.div);

		this.add();

	}
	public add(): void
	{
		var _tag = "";

		if(this.state=="main")
		{
			_tag += '<div class="_main">';
			_tag += '	<p class="_en">'+this.text[DataStorage.categoryId-1][0]+'</p>';
			_tag += '	<p class="_jp">'+this.text[DataStorage.categoryId-1][1]+'</p>';
			_tag += '	<p class="_num">'+DataStorage.usersstoryCategory.length+' STORIES</p>';
			_tag += "</div>";
		}
		else
		{
			_tag += '<div class="_sub">';
			_tag += '	<p class="_no">No.'+('000'+this.num).slice(-3)+'</p>';
			_tag += "</div>";
		}
		
		this.div.innerHTML += _tag;

	}
	public show(): void
	{
		gsap.to(this.div, 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});

		if(this.state=="main")
		{
			gsap.set(this.div.querySelector("._en"), {css:{display:"none", opacity:0}});
			gsap.set(this.div.querySelector("._jp"), {css:{display:"none", opacity:0}});
			gsap.set(this.div.querySelector("._num"), {css:{display:"none", opacity:0}});

			gsap.to(this.div.querySelector("._en"), 1.2, {css:{display:"block", opacity:1}, ease:Power1.easeInOut, delay:0.1});
			gsap.to(this.div.querySelector("._jp"), 1.2, {css:{display:"block", opacity:1}, ease:Power1.easeInOut, delay:0.2});
			gsap.to(this.div.querySelector("._num"), 1.2, {css:{display:"block", opacity:1}, ease:Power1.easeInOut, delay:0.3});
		}
	}
	public hide(): void
	{
		gsap.set(this.div, {css:{display:"none", opacity:0}});

	}
}