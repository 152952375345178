import { Event } from "../events/EventDispatcher";
import { DataStorage } from "../DataStorage";
import { SceneEvent } from "../SceneEvent";
import * as PIXI from 'pixi.js';
import { gsap, Power1 } from "gsap";

export class HistoryContents
{
	private stage: PIXI.Container;
	private num: number;

	private container: PIXI.Container;
	private hasPhoto: boolean;
	private isActive: boolean;

	private year: PIXI.Text;
	private photo: PIXI.Sprite;
	private credit: PIXI.Text;
	private en: PIXI.Text;
	private ja: PIXI.Text;

	private historyNum: number = 0;
	
	private changeBindThis: Function;

	constructor(stage: PIXI.Container, num: number)
	{
		this.stage = stage;
		this.num = num;

		if(DataStorage.historyData[this.num].photo.label.indexOf("null.png")=="-1") this.hasPhoto = true;

		this.container = new PIXI.Container();
		this.container.x = 750;
		if(this.num!=0)
		{
			gsap.set(this.container, {alpha:0, x:750+360, rotation:0.06});
		}
		this.stage.addChild(this.container);

		this.year = new PIXI.Text({style: { fontFamily:'BrilliantCutProMedium', fontSize:70*2, fill:0x5d5958, letterSpacing:0 }});
		this.year.text = DataStorage.historyData[this.num].year;
		if(this.hasPhoto)
		{
			this.year.x = 60*2-750;
			this.year.y = 140*2;
			this.container.addChild(this.year);

			this.photo = PIXI.Sprite.from(DataStorage.historyData[this.num].photo);
			this.photo.width = DataStorage.historyData[this.num].photo.width*2;
			this.photo.height = DataStorage.historyData[this.num].photo.height*2;
			this.photo.x = 750-750;
			this.photo.y = 230*2;
			this.photo.anchor.set(0.5, 0.0);
			this.container.addChild(this.photo);
	
			this.credit = new PIXI.Text({style: { fontFamily:'BrilliantCutProRegular', fontSize:16*2, fill:0x5d5958, letterSpacing:0, lineHeight:50, wordWrapWidth:1300, wordWrap:true, align:"center" }});
			this.credit.text = DataStorage.historyData[this.num].credit;
			this.credit.x = 750-750;
			this.credit.y = this.photo.y+DataStorage.historyData[this.num].photo.height*2+40*2;
			this.credit.anchor.set(0.5);
			this.container.addChild(this.credit);
		}
		else
		{
			this.year.x = 750-750;
			this.year.y = 197*4;
			this.year.anchor.set(0.5, 0.0);
			this.container.addChild(this.year);
		}

		this.en = new PIXI.Text({style: { fontFamily:'BrilliantCutProMedium', fontSize:26*2, fill:0x5d5958, letterSpacing:0, lineHeight:80, wordWrapWidth:1300, wordWrap:true }});
		this.en.text = DataStorage.historyData[this.num].en;
		this.en.x = 60*2-750;
		if(this.hasPhoto)
		{
			this.en.y = this.credit.y+this.credit.height+10;
		}
		else
		{
			this.en.y = 340*4;
		}
		this.container.addChild(this.en);

		this.ja = new PIXI.Text({style: { fontFamily:'NotoSerifJPMedium', fontSize:20*2, fill:0x5d5958, letterSpacing:0, lineHeight:70 }});
		this.ja.text = DataStorage.historyData[this.num].ja;
		this.ja.x = 60*2-750;
		this.ja.y = this.en.y+this.en.height+10;
		this.container.addChild(this.ja);

		this.changeBindThis = this.change.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.CHANGE_HISTORY, this.changeBindThis);
		this.change();

	}
	public change(): void
	{
		if(DataStorage.historyNum==this.num)
		{
			if(!this.isActive)
			{
				this.isActive = true;
	
				gsap.killTweensOf(this.container);
				gsap.to(this.container, 0.6, {alpha:1, x:750, rotation:0, ease:Power1.easeOut});
			}
		}
		else
		{
			if(this.isActive)
			{
				this.isActive = false;

				gsap.killTweensOf(this.container);
				if(this.historyNum>DataStorage.historyNum)
				{
					gsap.to(this.container, 0.6, {alpha:0, x:750+360, rotation:0.06, ease:Power1.easeOut});
				}
				else
				{
					gsap.to(this.container, 0.6, {alpha:0, x:750-360, rotation:-0.06, ease:Power1.easeOut});
				}
			}
		}
	
		this.historyNum = DataStorage.historyNum;
	}
}