import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { gsap, Power1 } from "gsap";

export class FooterView
{
	private element: HTMLElement;

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#footer");

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_FOOTER, this.addBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_TOP, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_FOOTER, this.removeBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_SEND_YOURHISTORY, this.removeBindThis);

	}
	public add(): void
	{
		gsap.to(this.element, {css:{display:"block"}});

	}
	public remove(): void
	{
		gsap.set(this.element, {css:{display:"none"}});

	}
}