import { Event } from "../events/EventDispatcher";
import { DataStorage } from "../DataStorage";
import { gsap, Power1, Power3 } from "gsap";

export class InsidePageView
{
	private element: HTMLElement;
	private text: String;
	private num: number;

	private div: HTMLElement;

	constructor(element:HTMLElement, text:String, num:number)
	{
		this.element = element;
		this.text = text;
		this.num = num;

		this.div = document.createElement('div');
		this.element.appendChild(this.div);

		this.add();

	}
	public add(): void
	{
		const _sentence = this.text.split("<br>");

		var _tag = "";

		_tag += '<div class="_hoge">';
		_tag += '	<p class="_no">No.'+('000'+this.num).slice(-3)+'</p>';
		_tag += '	<div class="_sentence">';
		for(let i=0; i<_sentence.length; i++)
		{
			_tag += [..._sentence[i]].map((char) => `<span>${char}</span>`).join("");
			_tag += "<br>";
		}
		_tag += "	</div>";
		_tag += "</div>";
		
		this.div.innerHTML += _tag;

	}
	public show(): void
	{
		gsap.set(this.div, {css:{display:"block"}});

		gsap.set(this.div.querySelector("._no"), {css:{display:"none", opacity:0}});
		gsap.to(this.div.querySelector("._no"), 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut, delay:0.3});

		const _p = Array.from(this.div.querySelectorAll("span"));

		for(let i=0; i<_p.length; i++)
		{
			gsap.killTweensOf(_p[i]);

			gsap.set(_p[i], {css:{opacity:0, y:DataStorage.window_w/750*20}});
			gsap.to(_p[i], 2.6+Math.random()*1.2, {css:{opacity:0.8, y:0}, ease:Power3.easeOut, delay:Math.random()*0.5});
		}
	}
	public hide(): void
	{
		gsap.set(this.div, {css:{display:"none"}});

	}
}