import { Event } from "../events/EventDispatcher";
import { DataStorage } from "../DataStorage";
import { SceneEvent } from "../SceneEvent";
import { gsap, Power1 } from "gsap";

export class BookView
{
	private element: HTMLElement;

	private image_open: HTMLElement[] = [];
	private image_yourStory: HTMLElement[] = [];
	private image_turnOver: HTMLElement[] = [];

	private state: string = "";		//open, close, next, prev
	private num: number;
	private timer: NodeJS.Timeout;

	private addBindThis: Function;
	private nextBindThis: Function;
	private prevBindThis: Function;
	private closeBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#book");

		this.element.querySelectorAll('._open img').forEach((element) =>
		{
			this.image_open.push(element as HTMLElement);
		});
		this.element.querySelectorAll('._yourStory img').forEach((element) =>
		{
			this.image_yourStory.push(element as HTMLElement);
		});
		this.element.querySelectorAll('._turnOver img').forEach((element) =>
		{
			this.image_turnOver.push(element as HTMLElement);
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_BOOK, this.addBindThis);

		this.nextBindThis = this.next.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_NEXT, this.nextBindThis);

		this.prevBindThis = this.prev.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_PREV, this.prevBindThis);

		this.closeBindThis = this.close.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_CLOSE, this.closeBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_BOOK, this.removeBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_SEND_YOURHISTORY, this.removeBindThis);
		
	}
	public add(): void
	{
		gsap.killTweensOf(this.element);

		gsap.to(this.element, 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});

		this.open();

	}
	public open(): void
	{
		this.state = "open";

		DataStorage.isBookOpen = false;

		gsap.killTweensOf(this.element.querySelectorAll('._open'));
		gsap.killTweensOf(this.element.querySelectorAll('._yourStory'));
		gsap.killTweensOf(this.element.querySelectorAll('._turnOver'));

		gsap.set(this.element.querySelectorAll('._open'), {css:{display:"block", scale:1.0}});
		gsap.set(this.element.querySelectorAll('._yourStory'), {css:{display:"block", scale:1.0}});
		gsap.set(this.element.querySelectorAll('._turnOver'), {css:{display:"none"}});

		if(DataStorage.state=="history")
		{
			gsap.to(this.element.querySelectorAll('._open'), 0.9, {css:{scale:1.7}, ease:Power1.easeInOut, delay:1.2});
			gsap.to(this.element.querySelectorAll('._yourStory'), 0.9, {css:{scale:1.7}, ease:Power1.easeInOut, delay:1.2});
		}

		if(this.timer) clearTimeout(this.timer);

		this.num = 0;
		this.openAnimation();

	}
	public openAnimation(): void
	{
		if(this.state!="open") return;

		for(let i=0; i<this.image_open.length; i++)
		{
			gsap.set(this.image_open[i], {css:{display:"none"}});
		}
		gsap.set(this.image_open[this.num], {css:{display:"block"}});

		for(let i=0; i<this.image_yourStory.length; i++)
		{
			gsap.set(this.image_yourStory[i], {css:{display:"none"}});
		}
		gsap.set(this.image_yourStory[this.num], {css:{display:"block"}});

		if(this.image_open.length-1>this.num)
		{
			this.num++;

			this.timer = setTimeout(this.openAnimation.bind(this), 30);
		}
		if(this.num==55)
		{
			DataStorage.isBookOpen = true;
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_OPEN_COMPLETE));
		}
	}
	public next(): void
	{
		this.state = "next";

		gsap.killTweensOf(this.element.querySelectorAll('._open'));
		gsap.killTweensOf(this.element.querySelectorAll('._yourStory'));
		gsap.killTweensOf(this.element.querySelectorAll('._turnOver'));

		gsap.set(this.element.querySelectorAll('._open'), {css:{display:"none"}});
		gsap.set(this.element.querySelectorAll('._yourStory'), {css:{display:"none"}});
		gsap.set(this.element.querySelectorAll('._turnOver'), {css:{display:"block"}});

		if(this.timer) clearTimeout(this.timer);

		this.num = 0;
		this.nextAnimation();

	}
	public nextAnimation(): void
	{
		if(this.state!="next") return;

		for(let i=0; i<this.image_turnOver.length; i++)
		{
			gsap.set(this.image_turnOver[i], {css:{display:"none"}});
		}
		gsap.set(this.image_turnOver[this.num], {css:{display:"block"}});

		if(this.num<this.image_turnOver.length-1)
		{
			this.num++;

			this.timer = setTimeout(this.nextAnimation.bind(this), 30);
		}
		if(this.num==15)
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_NEXT_COMPLETE));
		}
	}
	public prev(): void
	{
		this.state = "prev";

		gsap.killTweensOf(this.element.querySelectorAll('._open'));
		gsap.killTweensOf(this.element.querySelectorAll('._yourStory'));
		gsap.killTweensOf(this.element.querySelectorAll('._turnOver'));

		gsap.set(this.element.querySelectorAll('._open'), {css:{display:"none"}});
		gsap.set(this.element.querySelectorAll('._yourStory'), {css:{display:"none"}});
		gsap.set(this.element.querySelectorAll('._turnOver'), {css:{display:"block"}});

		if(this.timer) clearTimeout(this.timer);

		this.num = this.image_turnOver.length-10;
		this.prevAnimation();

	}
	public prevAnimation(): void
	{
		if(this.state!="prev") return;

		for(let i=0; i<this.image_turnOver.length; i++)
		{
			gsap.set(this.image_turnOver[i], {css:{display:"none"}});
		}
		gsap.set(this.image_turnOver[this.num], {css:{display:"block"}});

		if(this.num>0)
		{
			this.num--;

			this.timer = setTimeout(this.prevAnimation.bind(this), 30);
		}
		if(this.num==0)
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_PREV_COMPLETE));
		}
	}
	public close(): void
	{
		this.state = "close";

		gsap.killTweensOf(this.element.querySelectorAll('._open'));
		gsap.killTweensOf(this.element.querySelectorAll('._yourStory'));
		gsap.killTweensOf(this.element.querySelectorAll('._turnOver'));
		gsap.killTweensOf(this.element);

		gsap.set(this.element.querySelectorAll('._open'), {css:{display:"block"}});
		gsap.set(this.element.querySelectorAll('._yourStory'), {css:{display:"block"}});
		gsap.set(this.element.querySelectorAll('._turnOver'), {css:{display:"none"}});

		if(this.timer) clearTimeout(this.timer);

		this.num = this.image_open.length-10;
		this.closeAnimation();

		gsap.to(this.element.querySelectorAll('._open'), 0.6, {css:{scale:1.0}, ease:Power1.easeInOut});
		gsap.to(this.element.querySelectorAll('._yourStory'), 0.6, {css:{scale:1.0}, ease:Power1.easeInOut});

		gsap.to(this.element, 0.6, {css:{display:"none", opacity:0}, ease:Power1.easeInOut, delay:0.6});

		setTimeout(function()
		{
			if(this.state=="close") SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BACK_TO_TOP));
		}.bind(this), 700);
	}
	public closeAnimation(): void
	{
		if(this.state!="close") return;

		for(let i=0; i<this.image_open.length; i++)
		{
			gsap.set(this.image_open[i], {css:{display:"none"}});
		}
		gsap.set(this.image_open[this.num], {css:{display:"block"}});

		for(let i=0; i<this.image_yourStory.length; i++)
		{
			gsap.set(this.image_yourStory[i], {css:{display:"none"}});
		}
		gsap.set(this.image_yourStory[this.num], {css:{display:"block"}});

		if(this.num>0)
		{
			this.num--;

			this.timer = setTimeout(this.closeAnimation.bind(this), 30);
		}
		else
		{
			// SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_TOP));
		}
	}
	public remove(): void
	{
		gsap.killTweensOf(this.element);
		gsap.to(this.element, 0.3, {css:{opacity:0, display:"none"}, ease:Power1.easeInOut});

	}
}