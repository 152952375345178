import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { InsideCoverView } from '../view/InsideCoverView';
import { InsidePageView } from '../view/InsidePageView';
import { SwipeController } from "../controller/SwipeController";
import { BookSliderController } from '../controller/BookSliderController';
import { gsap, Power1 } from "gsap";
import Cookies from 'js-cookie';

export class EpisodeView
{
	private element: HTMLElement;
	
	private typo: any[];
	private slider: BookSliderController;

	private addBindThis: Function;
	private hideBindThis: Function;
	private showBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#episode");

		new SwipeController(this.element.querySelector("._swipe"));
		this.slider = new BookSliderController(this.element.querySelector("._slider"));

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_EPISODE, this.addBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.GET_EPISODE_BY_CATEGORY_ID_COMPLETE, this.addBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_OPEN_COMPLETE, this.addBindThis);

		this.hideBindThis = this.hide.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_NEXT, this.hideBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_PREV, this.hideBindThis);

		this.showBindThis = this.show.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_NEXT_COMPLETE, this.showBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_PREV_COMPLETE, this.showBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_EPISODE, this.removeBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_SEND_YOURHISTORY, this.removeBindThis);

	}
	public add(): void
	{
		if(!(DataStorage.hasEpisode && DataStorage.isBookOpen && DataStorage.state=="usersStory")) return;
		
		DataStorage.offMenu = "category_"+DataStorage.categoryId;
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.MENU_OFF));

		this.element.querySelector("._page").innerHTML = "";

		gsap.set(this.element, {css:{display:"block"}});

		this.typo = [];
		
		this.typo.push(new InsideCoverView(this.element.querySelector("._page"), "main", 0));

		for(let i=0; i<DataStorage.usersstoryCategory.length; i++)
		{
			this.typo.push(new InsideCoverView(this.element.querySelector("._page"), "sub", i+1));

			let _text = DataStorage.usersstoryCategory[i].episode.replace(/\r?\n/g, '<br>').split('<br>');
			let _hoge = "";
			for(let j=0; j<_text.length; j++)
			{
				for(let k=0; k<_text[j].length; k++)
				{
					this.element.querySelector("._text").innerHTML = _hoge+_text[j][k];
					if(this.element.querySelector("._text").clientHeight>DataStorage.window_w/750*550)
					{
						this.typo.push(new InsidePageView(this.element.querySelector("._page"), _hoge, i+1));

						_hoge = _text[j][k];
					}
					else
					{
						_hoge += _text[j][k];
					}
				}
				_hoge += "<br>";
			}
			this.typo.push(new InsidePageView(this.element.querySelector("._page"), _hoge, i+1));
		}

		this.element.querySelector("._text").innerHTML = "";

		DataStorage.pageNumMax = this.typo.length;
		DataStorage.pageNum = 0;

		if(DataStorage.pageNumMax>1)
		{
			this.slider.add();
		}

		this.show();

	}
	public show(): void
	{
		for(let i=0; i<this.typo.length; i++)
		{
			this.typo[i].hide();
		}

		gsap.killTweensOf(this.element.querySelector("._title"));
		gsap.killTweensOf(this.element.querySelector("._page"));
		gsap.killTweensOf(this.element);

		gsap.set(this.element.querySelector("._title"), {css:{display:"none", opacity:0}});
		gsap.set(this.element.querySelector("._page"), {css:{opacity:1, display:"block"}});
		gsap.set(this.element, {css:{opacity:1, display:"block"}});

		gsap.to(this.element.querySelector("._title"), 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});

		this.typo[DataStorage.pageNum].show();

		if(!Cookies.get('episodeTutorial'))
		{
			Cookies.set('episodeTutorial', 'true', {expires:DataStorage.cookies_expires});
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_EPISODE_TUTORIAL));
		}

		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_COVER));		//クリック許可

	}
	public hide(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_COVER));		//クリック禁止

		gsap.killTweensOf(this.element.querySelector("._title"));
		gsap.killTweensOf(this.element.querySelector("._page"));
		gsap.to(this.element.querySelector("._title"), 0.2, {css:{opacity:0, display:"none"}, ease:Power1.easeInOut});
		gsap.to(this.element.querySelector("._page"), 0.2, {css:{opacity:0, display:"none"}, ease:Power1.easeInOut});

	}
	public remove(): void
	{
		gsap.killTweensOf(this.element);
		gsap.to(this.element, 0.3, {css:{opacity:0, display:"none"}, ease:Power1.easeInOut});

		if(DataStorage.pageNumMax>1)
		{
			this.slider.remove();
		}
	}
}