import { Event } from "../events/EventDispatcher";
import { DataStorage } from "../DataStorage";
import { SceneEvent } from "../SceneEvent";
import * as PIXI from 'pixi.js';
import { gsap } from "gsap";

export class HistoryPoint
{
	private stage: PIXI.Container;
	private num: number;

	private container: PIXI.Container;
	private circle: PIXI.Graphics;

	private text: PIXI.Text;

	private positionBindThis: Function;
	private changeBindThis: Function;

	constructor(stage: PIXI.Container, num: number)
	{
		this.stage = stage;
		this.num = num;

		this.container = new PIXI.Container();
		this.container.visible = false;
		this.stage.addChild(this.container);

		this.circle = new PIXI.Graphics();
		this.circle.circle(0, 0, 15);
		this.circle.fill(0xe71e19);
		this.container.addChild(this.circle);

		this.text = new PIXI.Text({ text:"", style: new PIXI.TextStyle({ fontFamily:'BrilliantCutProMedium', fontSize:40, fill:0x7c7c7c, letterSpacing:0 }) })
		this.text.text = DataStorage.historyData[this.num].year;
		this.text.y = -60;
		this.text.anchor.set(0.5);
		this.container.addChild(this.text);

		this.positionBindThis = this.position.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.SET_HISTORY_POSITION, this.positionBindThis);
		this.position();

		this.changeBindThis = this.change.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.CHANGE_HISTORY, this.changeBindThis);
		this.change();

	}
	public position(): void
	{
		var _x = DataStorage.historyPointPosition.x-DataStorage.historyPointPosition.radius/2;
		var _y = DataStorage.historyPointPosition.y-DataStorage.historyPointPosition.radius/2;
		var _radius = DataStorage.historyPointPosition.radius;
		var _red = Math.PI/180*DataStorage.historyPointPosition.red;
		var _start = Math.PI/180*DataStorage.historyPointPosition.start;
		
		this.container.x = Math.cos(_red*this.num+_start)*_radius+_radius+_x;
		this.container.y = Math.sin(_red*this.num+_start)*_radius+_radius+_y;
		this.container.rotation = _red*this.num+_start+90*Math.PI/180;

		DataStorage.historyPointX[this.num] = this.container.x;

	}
	public change(): void
	{
		if(DataStorage.historyNum==this.num)
		{
			this.container.visible = true;
		}
		else
		{
			this.container.visible = false;
		}
	}
}