import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { gsap, Power1 } from "gsap";

export class BookSliderController
{
	private element: HTMLElement;
	
	private bar: HTMLElement;
	
	private startX: number = 0;
	private positionX: number = 0;

	private turnOverBindThis: Function;

	constructor(element: HTMLElement)
	{
		this.element = element;
		
		this.bar = this.element.querySelector("._bar");

		this.element.addEventListener("touchstart", this.touchstart.bind(this));
		this.element.addEventListener("touchmove", this.touchmove.bind(this));
		this.element.addEventListener("touchend", this.touchend.bind(this));

		this.turnOverBindThis = this.turnOver.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_NEXT, this.turnOverBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_PREV, this.turnOverBindThis);
		
	}
	public add(): void
	{
		this.positionX = 0;
		gsap.set(this.bar, {css:{left:this.positionX}});

		gsap.to(this.element, 0.6, {css:{opacity:1, display:"block"}, ease:Power1.easeInOut});

		this.element.querySelector("._num").textContent = (DataStorage.pageNum+1)+"/"+DataStorage.pageNumMax;

	}
	public touchstart(e:any): void
	{
		e.preventDefault();

		this.startX = e.touches[0].pageX;
	}
	public touchmove(e:any): void
	{
		e.preventDefault();

		this.positionX += e.changedTouches[0].pageX - this.startX;

		this.positionX = Math.max(0, this.positionX);
		this.positionX = Math.min(this.positionX, this.element.clientWidth-this.bar.clientWidth);

		gsap.set(this.bar, {css:{left:this.positionX}});

		this.element.querySelector("._num").textContent = Math.round(this.positionX/((this.element.clientWidth-this.bar.clientWidth)/(DataStorage.pageNumMax-1))+1)+"/"+DataStorage.pageNumMax;

		this.startX = e.changedTouches[0].pageX;

	}
	public touchend(e:any): void
	{
		e.preventDefault();

		let _pageNum = Math.round(this.positionX/((this.element.clientWidth-this.bar.clientWidth)/(DataStorage.pageNumMax-1)));
		if(DataStorage.pageNum<_pageNum)
		{
			DataStorage.pageNum = _pageNum;
	
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.PAGE_NEXT));
		}
		else if(DataStorage.pageNum>_pageNum)
		{
			DataStorage.pageNum = _pageNum;
	
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.PAGE_PREV));
		}
	}
	public turnOver(): void
	{
		this.positionX = (this.element.clientWidth-this.bar.clientWidth)/(DataStorage.pageNumMax-1)*DataStorage.pageNum;

		gsap.killTweensOf(this.bar);
		gsap.to(this.bar, 0.6, {css:{left:this.positionX}, ease:Power1.easeInOut});

		this.element.querySelector("._num").textContent = (DataStorage.pageNum+1)+"/"+DataStorage.pageNumMax;

	}
	public remove(): void
	{
		gsap.to(this.element, 0.6, {css:{opacity:0, display:"none"}, ease:Power1.easeInOut});

	}
}