import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { gsap } from "gsap";

export class SendYourhistoryView
{
	private element: HTMLElement;
	private p0: HTMLElement;
	private p1: HTMLElement;

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#sendYourhistory");
		this.p0 = this.element.querySelector("._p0");
		this.p1 = this.element.querySelector("._p1");
		
		this.element.querySelector('._next').addEventListener('click', (event) =>
		{
			gsap.set(this.p0, {css:{display:"none"}});
			gsap.set(this.p1, {css:{display:"block"}});

			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_FOOTER));
		});
		
		this.element.querySelector('._over18').addEventListener('click', (event) =>
		{
			location.href = DataStorage.ENQUETE_URL;
		});

		this.element.querySelector('._under18').addEventListener('click', (event) =>
		{
			location.href = DataStorage.MESSAGE_URL;
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_SEND_YOURHISTORY, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_SEND_YOURHISTORY, this.removeBindThis);

	}
	public add(): void
	{
		gsap.set(this.p0, {css:{display:"block"}});
		gsap.set(this.p1, {css:{display:"none"}});

		gsap.set(this.element, {css:{display:"block"}});

	}
	public remove(): void
	{
		gsap.set(this.element, {css:{display:"none"}});

	}
}