import { DataStorage } from "../DataStorage";
import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { gsap, Power1, Power3 } from "gsap";

export class OpeningView
{
	private element: HTMLElement;

	private p: HTMLElement[];

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#opening");

		let sentence = this.element.querySelector("._ja").innerHTML.split("<br>");
		let newTextContent = "";
		for(let i=0; i<sentence.length; i++)
		{
			newTextContent += [...sentence[i]].map((char) => `<span>${char}</span>`).join("");
			newTextContent += "<br>";
		}
		this.element.querySelector("._ja").innerHTML = newTextContent;
		
		this.p = Array.from(this.element.querySelector("._text").querySelectorAll("span"));
		
		this.element.querySelector('._enter').addEventListener('click', this.outro.bind(this));

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_OPENING, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_OPENING, this.removeBindThis);

	}
	public add(): void
	{
		gsap.set(this.element, {css:{display:"block"}});

		this.intro();

	}
	public intro(): void
	{
		gsap.to(this.element.querySelector("._logo"), 0.9, {css:{opacity:1}, ease:Power1.easeInOut});
		gsap.to(this.element.querySelector("._logo"), 1.5, {css:{marginTop:-DataStorage.window_w/750*497}, ease:Power1.easeInOut, delay:0.9});
		gsap.to(this.element.querySelector("._logo"), 1.5, {css:{width:DataStorage.window_w/750*358}, ease:Power1.easeOut, delay:0.9, onComplete:this.typo.bind(this)});

	}
	public typo(): void
	{
		gsap.set(this.element.querySelector("._text"), {css:{display:"block"}});

		for(let i=0; i<this.p.length; i++)
		{
			gsap.set(this.p[i], {css:{opacity:0, y:DataStorage.window_w/750*30}});
			gsap.to(this.p[i], 1.8, {css:{opacity:1, y:0}, ease:Power3.easeOut, delay:i*0.021});
		}

		const _t = 6.5;

		gsap.to(this.element.querySelector('._en'), 1.7, {css:{opacity:1}, ease:Power1.easeInOut, delay:_t});
		gsap.to(this.element.querySelector('._enter'), 1.2, {css:{opacity:1, pointerEvents:"auto"}, ease:Power1.easeInOut, delay:_t+0.6});

	}
	public outro(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_COVER));		//クリック禁止
		
		gsap.set(this.element.querySelector('._enter'), {css:{pointerEvents:"none"}});
		gsap.to(this.element.querySelector('._logo'), 0.9, {css:{opacity:0}, ease:Power1.easeInOut});
		gsap.to(this.element.querySelector('._text'), 0.9, {css:{opacity:0}, ease:Power1.easeInOut});

		gsap.to(this.element.querySelector('._book'), 0.9, {css:{opacity:1}, ease:Power1.easeIn, delay:0.6});
		gsap.to(this.element.querySelector('._book'), 1.5, {css:{opacity:0}, ease:Power1.easeOut, delay:0.6+0.9+1.2, onComplete:this.introComplete.bind(this)});

	}
	public introComplete(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_OPENING));
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_TOP));
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_HEADER));

	}
	public remove(): void
	{
		gsap.set(this.element, {css:{display:"none"}});

	}
}