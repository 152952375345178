import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from "../DataStorage";

export class SwipeController
{
	private element: HTMLElement;

	//タップ時の誤動作を防ぐためのスワイプ時の処理を実行しない最小距離
	private minimumDistance: number = 30;

	//スワイプ開始時の座標
	private startX: number = 0;
	private startY: number = 0;

	//スワイプ終了時の座標
	private endX: number = 0;
	private endY: number = 0;

	constructor(element:HTMLElement)
	{
		this.element = element;

		// 解説①：移動を開始した座標を取得
		this.element.addEventListener("touchstart", this.touchstart.bind(this));

		// 解説②：移動した座標を取得
		this.element.addEventListener("touchmove", this.touchmove.bind(this));

		// 解説③：移動距離から左右or上下の処理を実行
		this.element.addEventListener("touchend", this.touchend.bind(this));

	}
	public touchstart(e:any): void
	{
		e.preventDefault();

		this.startX = e.touches[0].pageX;
		this.startY = e.touches[0].pageY;
	}
	public touchmove(e:any): void
	{
		e.preventDefault();

		this.endX = e.changedTouches[0].pageX;
		this.endY = e.changedTouches[0].pageY;
	}
	public touchend(e:any): void
	{
		e.preventDefault();
		
		// スワイプ終了時にx軸とy軸の移動量を取得
		// 左スワイプに対応するためMath.abs()で+に変換
		const distanceX = Math.abs(this.endX - this.startX);
		const distanceY = Math.abs(this.endY - this.startY);

		// 左右のスワイプ距離の方が上下より長い && 小さなスワイプは検知しないようにする
		if (distanceX > distanceY && distanceX > this.minimumDistance)
		{
			if(this.endX-this.startX<0)
			{
				if(DataStorage.pageNum<DataStorage.pageNumMax-1)
				{
					DataStorage.pageNum++;

					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.PAGE_NEXT));
				}
			}
			else
			{
				if(DataStorage.pageNum>0)
				{
					DataStorage.pageNum--;

					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.PAGE_PREV));
				}
			}
		}
	}
}