import { Event } from "./events/EventDispatcher";
import { SceneEvent } from "./SceneEvent";
import { DataStorage } from "./DataStorage";
import { SceneController } from "./controller/SceneController";
import { LIFFTController } from "./controller/LIFFTController";
import { CoverView } from './view/CoverView';
import { HeaderView } from "./view/HeaderView";
import { FooterView } from "./view/FooterView";
import { OpeningView } from "./view/OpeningView";
import { TopView } from "./view/TopView";
import { BookView } from "./view/BookView";
import { EpisodeView } from "./view/EpisodeView";
import { SendYourhistoryView } from "./view/SendYourhistoryView";
import { SendYourhistoryModal } from "./view/SendYourhistoryModal";
import { HistoryView } from "./view/HistoryView";
import { TopTutorialView } from './view/TopTutorialView';
import { EpisodeTutorialView } from './view/EpisodeTutorialView';
import { HistoryTutorialView } from './view/HistoryTutorialView';
import { ErrorModal } from './view/ErrorModal';
import { gsap, Power1 } from "gsap";
import GUI from 'lil-gui';
import Cookies from 'js-cookie';

class Apps
{
	public constructor()
	{
		// console.log("top:v0.0.9");

		// Cookies.remove('topTutorial');
		// Cookies.remove('episodeTutorial');
		// Cookies.remove('historyTutorial');

		DataStorage.PAGE = "top";
		DataStorage.LIFF_ID = DataStorage.INDEX_ID;

		document.addEventListener('DOMContentLoaded', this.ready.bind(this));
		// window.onload = this.load.bind(this);
		window.onresize = this.resizing.bind(this);
		// window.onscroll = this.scroll.bind(this);
		window.addEventListener('load', this.load.bind(this));
		window.addEventListener('resize', this.resizing.bind(this));
		window.addEventListener('scroll', this.scroll.bind(this));

	}
	public ready(): void
	{
		// console.log("Apps.ready");

		if(navigator.userAgent.indexOf('iPhone')>0 || navigator.userAgent.indexOf('iPod')>0 || navigator.userAgent.indexOf('iPad')>0 || navigator.userAgent.indexOf('Android')>0)
		{
			//SP
			DataStorage.isSP = true;
			document.documentElement.classList.add("_sp");
			document.body.classList.add("_SP");
		}
		else
		{
			//PC
			document.documentElement.classList.add("_pc");
			document.body.classList.add("_PC");
		}

		//ハッシュを調べる
		if(location.hash=="#debug")
		{
			DataStorage.isDebug = true;
		}

		//立ち上げ時サイズ
		DataStorage.init_w = document.documentElement.clientWidth;
		DataStorage.init_h = document.documentElement.clientHeight;
		// DataStorage.init_w = window.innerWidth;
		// DataStorage.init_h = window.innerHeight;

		this.resizing();
		this.scroll();

	}
	public load(): void
	{
		// console.log("Apps.load");

		// DataStorage.gui = new GUI();

		this.init();

	}
	//表示
	public init(): void
	{
		// console.log("Apps.init");
		
		new CoverView();
		new HeaderView();
		new FooterView();
		new OpeningView();
		new TopView();
		new BookView();
		new EpisodeView();
		new SendYourhistoryView();
		new SendYourhistoryModal();
		new HistoryView();
		new TopTutorialView();
		new EpisodeTutorialView();
		new HistoryTutorialView();
		new ErrorModal();

		new SceneController();
		new LIFFTController();

		this.resizing();
		this.scroll();
		
	}
	public resizing(): void
	{
		// console.log("Apps.resizing");
		
		DataStorage.window_w = document.documentElement.clientWidth;
		DataStorage.window_h = document.documentElement.clientHeight;
		// DataStorage.window_w = window.innerWidth;
		// DataStorage.window_h = window.innerHeight;

		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.WINDOW_RESIZE));

	}
	public scroll(): void
	{
		// console.log("Apps.scroll");

		DataStorage.scrollTop = window.scrollY;
		DataStorage.scrollLeft = window.scrollX;

		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.WINDOW_SCROLL));

	}
}
new Apps();
