import { Event } from "../events/EventDispatcher";
import { gsap } from "gsap";

export class ListSliderController
{
	private element: HTMLElement;
	private list: HTMLElement;
	
	private bar: HTMLElement;
	
	private startX: number = 0;
	private positionX: number = 0;

	constructor(element: HTMLElement, list: HTMLElement)
	{
		this.element = element;
		this.list = list;
		
		this.bar = this.element.querySelector("._bar");

		this.element.addEventListener("touchstart", this.touchstart.bind(this));
		this.element.addEventListener("touchmove", this.touchmove.bind(this));
		this.list.addEventListener('scroll', this.scroll.bind(this));
		
	}
	public touchstart(e:any): void
	{
		e.preventDefault();

		this.startX = e.touches[0].pageX;
	}
	public touchmove(e:any): void
	{
		e.preventDefault();

		this.positionX += e.changedTouches[0].pageX - this.startX;

		this.positionX = Math.max(0, this.positionX);
		this.positionX = Math.min(this.positionX, this.element.clientWidth-this.bar.clientWidth);

		gsap.set(this.bar, {css:{left:this.positionX}});

		this.list.scrollTo(this.positionX*(this.list.scrollWidth-this.list.clientWidth)/(this.element.clientWidth-this.bar.clientWidth), 0);

		this.startX = e.changedTouches[0].pageX;

	}
	public scroll(): void
	{
		this.positionX = this.list.scrollLeft*(this.element.clientWidth-this.bar.clientWidth)/(this.list.scrollWidth-this.list.clientWidth);

		gsap.set(this.bar, {css:{left:this.positionX}});
		
	}
}