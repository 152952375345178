import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from "../DataStorage";
import { gsap, Power1, Power3 } from "gsap";

export class EpisodeTutorialView
{
	private element: HTMLElement;

	private hand: HTMLElement;
	private hand_1: HTMLElement;
	private hand_2: HTMLElement;

	private isActiv: boolean;

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#episodeTutorialModal");

		this.hand = this.element.querySelector("._hand");
		this.hand_1 = this.element.querySelector("._hand_1");
		this.hand_2 = this.element.querySelector("._hand_2");

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_EPISODE_TUTORIAL, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_EPISODE_TUTORIAL, this.removeBindThis);

	}
	public add(): void
	{
		if(this.isActiv) return;

		this.isActiv = true;

		gsap.set(this.element, {css:{opacity:1, display:"block"}});

		gsap.killTweensOf(this.hand);
		gsap.set(this.hand, {css:{opacity:0, display:"none", x:0, y:DataStorage.window_w/750*200}});
		gsap.to(this.hand, 0.6, {css:{opacity:1, display:"block", y:0}, ease:Power1.easeOut, delay:0.6});

		gsap.to(this.hand, 1.2, {css:{x:-DataStorage.window_w/750*150}, ease:Power3.easeInOut, delay:1.5});

		gsap.set(this.hand_1, {css:{display:"none"}, delay:1.5-0.1});
		gsap.set(this.hand_2, {css:{display:"block"}, delay:1.5-0.1});

		gsap.set(this.hand_1, {css:{display:"block"}, delay:1.5-0.1+0.6});
		gsap.set(this.hand_2, {css:{display:"none"}, delay:1.5-0.1+0.6});

		setTimeout(function()
		{
			DataStorage.pageNum = 1;
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.PAGE_NEXT));
		},1700);

		gsap.to(this.hand, 1.0, {css:{x:DataStorage.window_w/750*150}, ease:Power1.easeInOut, delay:2.8});

		gsap.set(this.hand_1, {css:{display:"none"}, delay:2.8-0.1});
		gsap.set(this.hand_2, {css:{display:"block"}, delay:2.8-0.1});

		gsap.set(this.hand_1, {css:{display:"block"}, delay:2.8-0.1+0.6});
		gsap.set(this.hand_2, {css:{display:"none"}, delay:2.8-0.1+0.6});

		setTimeout(function()
		{
			DataStorage.pageNum = 0;
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.PAGE_PREV));
		},3000);

		gsap.to(this.hand, 1.2, {css:{opacity:0, display:"none", y:DataStorage.window_w/750*200}, ease:Power1.easeIn, delay:3.0+0.6, onComplete:this.remove.bind(this)});

	}
	public remove(): void
	{
		gsap.set(this.element, {css:{opacity:0, display:"none"}});

	}
}