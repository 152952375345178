import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { APIController } from '../controller/APIController';
import { ListSliderController } from '../controller/ListSliderController';
import { gsap, Power1, Power3, Power4 } from "gsap";
import Cookies from 'js-cookie';

export class TopView
{
	private element: HTMLElement;
	
	private container: HTMLElement;
	private slider: HTMLElement;
	private choice: HTMLElement;

	private addBindThis: Function;
	private removeBindThis: Function;
	private backBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#top");

		this.container = this.element.querySelector("._container");
		this.slider = this.element.querySelector("._slider");

		new ListSliderController(this.element.querySelector("._slider"), this.element.querySelector("._list"));
		
		this.element.querySelector('._shareYourStory').addEventListener('click', (event) =>
		{
			if(DataStorage.yourhistoryEpisode.length>0)
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_SEND_YOUR_HISTORY_MODAL));
			}
			else
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_SEND_YOURHISTORY));
			}
		});

		this.element.querySelector('._help').addEventListener('click', (event) =>
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_TOP_TUTORIAL));
		});

		this.container.querySelector('._history').addEventListener('click', (event) =>
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_COVER));		//クリック禁止

			this.choice = this.container.querySelector('._history') as HTMLElement;
			gsap.to(this.choice, 1.2, {css:{scale:1.1, y:-DataStorage.window_w/750*3, zIndex:5}, ease:Power3.easeOut});

			gsap.to(this.element, 0.9, {css:{opacity:0}, ease:Power1.easeIn, delay:0.3, onComplete:this.history.bind(this)});

			setTimeout(function()
			{
				DataStorage.state = "history";
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_BOOK));
			},260);
		});

		this.container.querySelectorAll('._usersStory').forEach((element) =>
		{
			element.addEventListener('click', (event) =>
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_COVER));		//クリック禁止

				this.choice = element as HTMLElement;
				gsap.to(this.choice, 1.2, {css:{scale:1.1, y:-DataStorage.window_w/750*3, zIndex:5}, ease:Power3.easeOut});

				DataStorage.categoryId = Number(this.choice.dataset.id);

				gsap.to(this.element, 0.6, {css:{opacity:0}, ease:Power1.easeIn, delay:0.3, onComplete:this.usersStory.bind(this)});

				setTimeout(function()
				{
					DataStorage.state = "usersStory";
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_BOOK));
				},260);
			});
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_TOP, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_TOP, this.removeBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_SEND_YOURHISTORY, this.removeBindThis);

		this.backBindThis = this.back.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.BACK_TO_TOP, this.backBindThis);

	}
	public add(): void
	{
		gsap.to(this.element, 0.9, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});

		let _t = 9*0.6;
		gsap.set(this.element.querySelector("._history"), {css:{x:DataStorage.window_w/750*900}});
		gsap.to(this.element.querySelector("._history"), 2.5, {css:{x:0}, ease:Power4.easeOut, delay:0*0.2});

		gsap.set(this.element.querySelector("._usersStory_0"), {css:{x:DataStorage.window_w/750*900}});
		gsap.to(this.element.querySelector("._usersStory_0"), 2.5, {css:{x:0}, ease:Power4.easeOut, delay:1*0.2});

		_t += 8*0.6;
		gsap.set(this.element.querySelector("._usersStory_1"), {css:{x:DataStorage.window_w/750*900}});
		gsap.to(this.element.querySelector("._usersStory_1"), 2.5, {css:{x:0}, ease:Power4.easeOut, delay:2*0.2});

		_t += 7*0.6;
		gsap.set(this.element.querySelector("._usersStory_2"), {css:{x:DataStorage.window_w/750*900}});
		gsap.to(this.element.querySelector("._usersStory_2"), 2.5, {css:{x:0}, ease:Power4.easeOut, delay:3*0.2});

		_t += 6*0.6;
		gsap.set(this.element.querySelector("._usersStory_3"), {css:{x:DataStorage.window_w/750*900}});
		gsap.to(this.element.querySelector("._usersStory_3"), 2.5, {css:{x:0}, ease:Power4.easeOut, delay:4*0.2});

		_t += 5*0.6;
		gsap.set(this.element.querySelector("._usersStory_4"), {css:{x:DataStorage.window_w/750*900}});
		gsap.to(this.element.querySelector("._usersStory_4"), 2.5, {css:{x:0}, ease:Power4.easeOut, delay:5*0.2});
		
		gsap.to(this.container, 2.6, {css:{opacity:1}, ease:Power4.easeInOut, onComplete:this.slideComplete.bind(this)});
		// gsap.to(this.slider, 0.3, {css:{opacity:1}, ease:Power1.easeInOut, delay:1.2});

		gsap.set(this.element.querySelector("._shadow"), {css:{scaleX:1.5}});
		gsap.to(this.element.querySelector("._shadow"), 2.6, {css:{scaleX:1, opacity:1}, ease:Power1.easeOut, delay:0.3});

		gsap.to(this.element.querySelector("._reflection"), 1.9, {css:{display:"block", opacity:1}, ease:Power1.easeIn, delay:1.5});

		gsap.set(this.element.querySelector("._shareYourStory"), {css:{y:30}});
		gsap.to(this.element.querySelector("._shareYourStory"), 0.6, {css:{display:"block", opacity:1, y:10}, ease:Power1.easeOut, delay:1.7});

		gsap.set(this.element.querySelector("._help"), {css:{scale:0.9}});
		gsap.to(this.element.querySelector("._help"), 0.6, {css:{display:"block", opacity:1, scale:1}, ease:Power1.easeInOut, delay:1.7});

	}
	public slideComplete(): void
	{
		if(!Cookies.get('topTutorial'))
		{
			Cookies.set('topTutorial', 'true', {expires:DataStorage.cookies_expires});
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_TOP_TUTORIAL));

			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_COVER));		//クリック許可
		}
		else
		{
			this.backComplete();
		}
	}
	public history(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_TOP));

	}
	public usersStory(): void
	{
		APIController.getEpisodeByCategoryId(DataStorage.categoryId);

		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_TOP));
	}
	public remove(): void
	{
		gsap.killTweensOf(this.element);
		gsap.killTweensOf(this.container);
		gsap.killTweensOf(this.slider);
		
		gsap.set(this.element, {css:{opacity:0}});

	}
	public back(): void
	{
		gsap.set(this.container, {css:{opacity:1, left:0}});
		gsap.to(this.element, 0.9, {css:{opacity:1}, ease:Power1.easeInOut});
		if(DataStorage.state!="top")
		{
			gsap.to(this.choice, 1.2, {css:{scale:1, y:0, zIndex:0}, ease:Power3.easeOut, delay:0.3, onComplete:this.backComplete.bind(this)});
		}
		else
		{
			gsap.to(this.choice, 1.2, {css:{scale:1, y:0, zIndex:0}, ease:Power3.easeOut, delay:0.3});

			this.backComplete();
		}
	}
	public backComplete(): void
	{
		if(DataStorage.quickBookOpen=="history")
		{
			(this.container.querySelector('._history') as HTMLElement).click();
		}
		else if(DataStorage.quickBookOpen=="category_1")
		{
			(this.container.querySelector('._usersStory_0') as HTMLElement).click();
		}
		else if(DataStorage.quickBookOpen=="category_2")
		{
			(this.container.querySelector('._usersStory_1') as HTMLElement).click();
		}
		else if(DataStorage.quickBookOpen=="category_3")
		{
			(this.container.querySelector('._usersStory_2') as HTMLElement).click();
		}
		else if(DataStorage.quickBookOpen=="category_4")
		{
			(this.container.querySelector('._usersStory_3') as HTMLElement).click();
		}
		else if(DataStorage.quickBookOpen=="category_5")
		{
			(this.container.querySelector('._usersStory_4') as HTMLElement).click();
		}
		else
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_COVER));		//クリック許可
		
			DataStorage.offMenu = "top";
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.MENU_OFF));
		}
		DataStorage.quickBookOpen = "";

	}
}