import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { gsap, Power1 } from "gsap";

export class TopTutorialView
{
	private element: HTMLElement;

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#topTutorialModal");

		this.element.querySelector('._close').addEventListener('click', (event) =>
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_TOP_TUTORIAL));
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_TOP_TUTORIAL, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_TOP_TUTORIAL, this.removeBindThis);

	}
	public add(): void
	{
		gsap.killTweensOf(this.element);
		gsap.set(this.element, {css:{display:"block"}});
		gsap.to(this.element, 1.2, {css:{opacity:1}, ease:Power1.easeIn, delay:0.6});

	}
	public remove(): void
	{
		gsap.killTweensOf(this.element);
		gsap.to(this.element, 0.3, {css:{opacity:0, display:"none"}, ease:Power1.easeInOut});

	}
}